import React, { useEffect, useState } from 'react'

import i18next from 'i18next'

import { BrowserRouter } from 'react-router-dom'

import { AppContext, useAppStateContext } from 'unno-comutils'
import { ToastContainer } from 'unno-comutils/Dialog'
import { checkLogin } from 'unno-comutils/connect'

import 'unno-comutils/style/core.css'
import './style/app.scss'

import Layout from './layout'
import Router from './router'

import './service/i18n'
import { setDateLocale } from './utils'
import { User } from 'unno-comutils/utils'
import { Wait } from 'unno-comutils/ui'
import { handleEvent$ } from './service/state'
import { LOCAL_SETTINGs } from './var'

export default function App (props: any) {
    const [wait, setWait] = useState(0)
    const appState = useAppStateContext()

    useEffect(() => {
        const hostname = window.location.hostname
        const metaOriginTrial: any = document.getElementById('meta-origin-trial')

        // https://developer.chrome.com/origintrials/#/trials/my

        if (metaOriginTrial) {
            if (hostname.includes('gpsiam.net'))
                metaOriginTrial.content = 'AlQ2tB2FrPBEElrD3x4y287sXO7T4VdmlsF8Rx3kkffqmyZ5mBIVfvPTTP6htJSG0euKD3v6YHNsdOHwwov98QQAAAB0eyJvcmlnaW4iOiJodHRwczovL2dwc2lhbS5uZXQ6NDQzIiwiZmVhdHVyZSI6IlVucmVzdHJpY3RlZFNoYXJlZEFycmF5QnVmZmVyIiwiZXhwaXJ5IjoxNzM5OTIzMTk5LCJpc1N1YmRvbWFpbiI6dHJ1ZX0='
            else if (hostname.includes('gpsiam.app'))
                metaOriginTrial.content = 'Ar/f3Z2JTiGqEByTnJn+bTe4a7U7bKA7zmS9xWvkGQTz2+EkW08FRUeW+52pg3WVQYhKjbtgTOQSncQFG9aMUA8AAAB0eyJvcmlnaW4iOiJodHRwczovL2dwc2lhbS5hcHA6NDQzIiwiZmVhdHVyZSI6IlVucmVzdHJpY3RlZFNoYXJlZEFycmF5QnVmZmVyIiwiZXhwaXJ5IjoxNzM5OTIzMTk5LCJpc1N1YmRvbWFpbiI6dHJ1ZX0='
            else if (hostname.includes('ntechgps.net'))
                metaOriginTrial.content = 'Aqad+aSd7BGQ14F0KKJr5LSKa0WTAqZjwoSzdwlOKKh9FVwbOm9cvS6MQpjYok3W5Pixig8T41wtqXOpPgQ5Qw8AAAB2eyJvcmlnaW4iOiJodHRwczovL250ZWNoZ3BzLm5ldDo0NDMiLCJmZWF0dXJlIjoiVW5yZXN0cmljdGVkU2hhcmVkQXJyYXlCdWZmZXIiLCJleHBpcnkiOjE3Mzk5MjMxOTksImlzU3ViZG9tYWluIjp0cnVlfQ=='
        }
    }, [])

    useEffect(() => {
        // เหตุการณ์สำหรับ แผนที่ longdo -> google map
        document.addEventListener('error', e => {
            //console.log('document.addEventListener -- error')
            const t = e.target as Element
            if (t && t.nodeName === 'IMG') {
                const u = t.getAttribute('src')
                if (u && u.startsWith('https://mapfile.gpsiam.app')) {
                    const url = new URL(u)
                    // เปลี่ยนผ่านแผนที่  932 -> 969
                    if (url.pathname.startsWith('/932')) {
                        t.setAttribute('src', 'https://mapfile.gpsiam.app/969' + url.pathname.substring(4))
                    }
                    else if (url.pathname.startsWith('/9') && url.pathname.endsWith('.jpg')) {
                        //console.log('set to -> ' + 'https://mapsat.mooaun.com' + url.pathname + '?cf=1')
                        t.setAttribute('src', 'https://mapsat.mooaun.com' + url.pathname + '?cf=1')
                    }
                }
            }
        }, true)

        // --------------

        setTimeout(() => {
            checkLogin().then((d: any) => {
                if (d.ok) {
                    i18next.changeLanguage(d.user.lang)
                    setDateLocale(d.user.lang)

                    const user = new User(d.user)
                    appState.setUser(user)
                    window.optionNewTile = user.allow('extra_map_newtile')
                }
                else {
                    appState.setUser(null)
                    //logout()
                }
                setWait(2)
            })
        }, 200)

        // --------------

        const sub$ = handleEvent$('app_theme_change', function (data) {
            localStorage.setItem(LOCAL_SETTINGs.THEME, data)
            document.body.className = 'theme-' + data
        })
        const theme = localStorage.getItem(LOCAL_SETTINGs.THEME)
        if (theme) {
            document.body.className = 'theme-' + theme
        }

        return () => {
            sub$.unsubscribe()
        }
    }, [])

    if (wait < 2) return <Wait color={wait ? 'blue' : 'pink'}/>

    return <AppContext.Provider value={appState}>
        <div className="layout">
            <BrowserRouter>
                {!!appState.user ? <Layout><Router/></Layout> : <Router/>}
            </BrowserRouter>
            <ToastContainer/>
        </div>
    </AppContext.Provider>
}
