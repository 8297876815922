import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { resources } from '../lang'

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'th',
        fallbackLng: 'en',
        //debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    })

export default i18n
// https://stackoverflow.com/questions/57002695/how-to-add-translations-to-date-using-react-i18next