import React, { useEffect, useState } from 'react'

import { useUser } from 'unno-comutils'
import { PageScroll } from 'unno-comutils/ui'

import { Markdown } from '../components/common'
import { loadDocument } from '../components/document'

export default function DocumentView (props: { name: string }) {
    const user = useUser()
    const [document, setDocument] = useState<any>(null)

    // ----- MEMO

    useEffect(() => {
        loadDocument(props.name, user).then((doc) => setDocument(doc))
    }, [user])

    // ----- RENDER

    return <>
        {document && <div className="document-content">
            <div className="document-content-header">{document.title}</div>
            <PageScroll container className="document-content-body">
                <Markdown>{document.content}</Markdown>
            </PageScroll>
        </div>}
    </>
}
