import { enUS, th } from 'date-fns/locale'

import { format as dFormat, isDate, isValid } from 'date-fns'

import { date, get, num, post, qsset, sameDate, todate } from 'unno-comutils'
import { isValidDate } from 'unno-comutils/utils'
import { hasToken } from 'unno-comutils/connect'

import { COLORs } from './var'

export const randomId = (num = 10) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let eid = ''
    for (let i = 0; i < num; i++)
        eid += characters.charAt(Math.floor(Math.random() * charactersLength))
    return eid
}

export const randomNumber = (num = 6) => Math.round(Math.random() * Math.pow(10, num))

export const symb = (pack: string, file: string, mode?: 'line' | 'outline') => 'https://files.un-no.net/static/symb/' + pack + (mode ? '_' + mode : '') + '/' + file + '.svg'

export const name2hex = (name: string) => COLORs.find((c: any) => c.name === name)?.code || '#f44336'

export const hex2rgb = (code: string, op: number) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(code)
    if (result)
        return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${op})`
    return `rgba(0, 0, 0, ${op})`
}

export function removeDash (text: any) {
    return text.trim().replace(/^-/g, '').trim()
}

export function groupBy (xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
    }, {})
}

//{ datas, onGroupCheck, emptyGroup }
function _itemSort (sortName: string, sortDesc: number, a: any, b: any) {
    if (sortName === 'status') {
        if (a.status && b.status) {
            if (a.status.code === b.status.code)
                return (a.speed > b.speed ? -1 : 1) * sortDesc
            return (a.status.code > b.status.code ? -1 : 1) * sortDesc
        }
    }
    if (sortName === 'name' || sortName === 'device')
        return (a.name > b.name ? -1 : 1) * sortDesc
    if (sortName === 'time')
        return (todate(a.time) > todate(b.time) ? -1 : 1) * sortDesc
    return 0
}

export function groupDataTable (datas: any, hides: string[], sort?: string) {
    const sorts = sort ? sort.split(':') : []
    const sortName = sorts[0] ?? ''
    const sortDesc = sorts.length >= 2 && sorts[1] === 'desc' ? -1 : 1

    if (!datas.some((e: any) => e.group !== '')) {
        if (sortName !== '') return datas.sort((a: any, b: any) => _itemSort(sortName, sortDesc, a, b))
        return datas
    }

    const outputs = []
    const groups = groupBy(datas, 'group')

    for (const g in groups) {
        if (groups.hasOwnProperty(g)) {
            outputs.push({ isGroup: true, name: g, count: groups[g]?.length || 0 })
            if (hides.indexOf(g) < 0) {
                if (sortName)
                    groups[g].sort((a: any, b: any) => _itemSort(sortName, sortDesc, a, b))
                groups[g].forEach((d: any) => outputs.push(d))
            }
        }
    }

    return outputs
}

export const role = (key: any, user: any) => !key || user?.roles.indexOf(key) >= 0 || false

export const roleDev = (menu: any, user: any) => !menu.dev || user.id === 900 || user.id === 1000 || user.id === 1002

export const dateRangeText = (dates: any, t: any, f: 'S' | 'M' = 'S') => {
    if (dates.length === 2) {
        const d0 = tdate(t, dates[0], f).split(' ')
        const d1 = tdate(t, dates[1], f).split(' ')
        if (sameDate(dates[0], dates[1], 'y') && sameDate(dates[0], dates[1], 'm'))
            return [d0[0], '-', ...d1].join(' ')
        if (sameDate(dates[0], dates[1], 'y'))
            return [d0[0], d0[1], '-', ...d1].join(' ')
        return [...d0, '-', ...d1].join(' ')
    }
    return ''
}

export const datetimeRangeText = (start: any, end: any) => {
    if (sameDate(start, end, 'd'))
        return [date(start, 'St'), '-', date(end, 't')].join(' ')
    return [date(start, 'St'), '-', date(end, 'St')].join(' ')
}

export const timeDuration = (time: any, sec: boolean = true) => {
    if (!time) return '-'
    const h = Math.floor(time / 3600)
    const m = Math.floor((time - (h * 3600)) / 60)
    const s = time % 60
    return `${h}`.padStart(2, '0') + ':' + `${m}`.padStart(2, '0') + (sec ? ':' + `${s}`.padStart(2, '0') : '')
}

export const timeHour = (time: any) => {
    if (!time) return '-'
    const h = Math.floor(time / 3600)
    const m = Math.floor((time - (h * 3600)) / 60)
    return (h >= 24 ? '(+' + Math.floor(h / 24) + ') ' : '') + `${h >= 24 ? h % 24 : h}`.padStart(2, '0') + ':' + `${m}`.padStart(2, '0')
}

export const distanceText = (m: any) => {
    if (!m) return '-'
    m = num(m)
    if (m < 1000) return num(m, 0) + 'm'
    return num(m / 1000, 1) + 'km'
}

const addressEndpoint = process.env.REACT_APP_BACKEND?.substring(0, process.env.REACT_APP_BACKEND?.length - 2) + '/ring.php'
export const address = (latitude: any, longitude: any, user?: any) => {
    return get(addressEndpoint, { latitude, longitude, asset: user?.assetId, lang: user?.lang }, true)
}
export const addressMass = (points: any[], user?: any) => {
    return post(addressEndpoint, { points }, { asset: user?.assetId, lang: user?.lang }, true)
}

export const deg2rad = (deg: any) => deg * (Math.PI / 180)

export const distance = (lat1: any, lon1: any, lat2: any, lon2: any) => {
    const dLat = deg2rad(lat2 - lat1)  // deg2rad below
    const dLon = deg2rad(lon2 - lon1)
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    return 6371 * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
}

export const loadMeta = (options: any, setMeta: (meta: any) => void) => {
    get('app/meta', options).then(({ ok, ...meta }) => ok && setMeta(meta))
}

export const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
}

export const sumInfo = (t: any, dt: number, dr: number, s?: boolean) => num(dt / 1000, 2) + ' ' + t('UNIT_KM') + (s ? '' : ' ' + t('TEXT_DURATION')) + ' ' + timeDuration(dr)

// ----- DOM

export function waitFor_elementById (emlId: string) {
    return new Promise(resolve => {
        const $el = document.getElementById(emlId)
        if ($el) return resolve($el)
        const observer = new MutationObserver(mutations => {
            const $el = document.getElementById(emlId)
            if ($el) {
                resolve($el)
                observer.disconnect()
            }
        })
        observer.observe(document.body, {
            childList: true,
            subtree: true
        })
    })
}

// ----- DATA

export const jsond = (data: string | null) => {
    if (data === null) return null
    try {
        return JSON.parse(data)
    } catch (e) {
        return null
    }
}

export const iconModal = (id: number) => id > 0 ? 'pencil-alt blue' : 'plus green'

export const exportUrl = (endpoint: string) => process.env.REACT_APP_BACKEND + endpoint + (endpoint.includes('?') ? '&' : '?') + '__TOKEN=' + hasToken() + '&_rand=' + (new Date()).getTime()

export const reportUrl = (endpoint: string, params?: any) => process.env.REACT_APP_BACKDOC + endpoint + (endpoint.includes('?') ? '&' : '?') + qsset({
    params: JSON.stringify(params),
    __TOKEN: hasToken(),
    _rand: (new Date()).getTime()
}, true)

export const optionUnit = (dataSets: number[], t: any, options: { unit?: string, unitValue?: string, prepend?: any }) =>
    [...(options?.prepend || []), ...dataSets.map(d => ({
        id: d,
        name: options.unitValue ? t(options.unitValue, { value: d }) : d + ' ' + t(options.unit)
    }))].filter(Boolean)

// ----- HASH

export const hkey = (data: any) => {
    return JSON.stringify(data).split('').reduce((a, b) => {
        a = ((a << 5) - a) + b.charCodeAt(0)
        return a & a
    }, 0)
}

export const hhash = () => {
    const h = document.location.hash
    if (h && h.length > 1) {
        const ex = h.substr(1).split(',')
        if (ex.length > 1)
            return { type: ex[0], id: num(ex[1]), raw: ex }
    }
    return null
}

export const hhashUpdate = (hash: string) => window.location.hash = hash

export const hhashRemove = () => window.location.hash = ''

export const filterSave = (d: any[]) => d.filter((d: any) => !d.ignore).map(({ ignore, key, ...d }: any) => d)

// ----- DATE

let locale = 'th'

export const tdate = (t: any, d: any, f: 'S' | 'St' | 'ST' | 'M' | 'Mt' | 'MT') => {
    if (!d) return ''
    if (!(d instanceof Date)) d = todate(d)
    if (!d) return ''

    const df = date(d, f)?.split(' ')
    df[1] = t((f.startsWith('S') ? 'DATE_MONTH_SHORT_' : 'DATE_MONTH_') + (d.getMonth() + 1))
    return df.join(' ')
}

export const todateUTC = (time: any, rev?: boolean) => {
    const t = todate(time)
    const userTimezoneOffset = t.getTimezoneOffset() * (rev ? 60000 : 60000 * -1)
    return new Date(Date.UTC(t.getUTCFullYear(), t.getUTCMonth(), t.getUTCDate(), t.getUTCHours(), t.getUTCMinutes(), t.getUTCSeconds()) + userTimezoneOffset)
}

export const dedateUTC = (time: any) => {
    const t = todate(time)
    const userTimezoneOffset = t.getTimezoneOffset() * 60000
    return new Date(Date.UTC(t.getUTCFullYear(), t.getUTCMonth(), t.getUTCDate(), t.getUTCHours(), t.getUTCMinutes(), t.getUTCSeconds()) + userTimezoneOffset)
}

export const cdate = (time: any) => todateUTC(time).getTime()
export const rcdate = (time: any) => todateUTC(time, true).getTime()

export const setDateLocale = (lang: string) => {
    locale = lang
    return locale
}

export const dateLocale = () => {
    if (locale === 'th') return th
    return enUS
}

export function i18ndate (d: any, format?: string, def?: string) {
    if (!format) format = 'S'
    if (!(d instanceof Date)) d = todate(d)

    if (!isDate(d) || !isValid(d)) {
        return def === undefined ? '' : def
    }
    // format
    // L = D MMMM YYYY
    // M = D MMM YYYY
    // S = D MMM YY

    // P = DD/MM/YYYY
    // Pt = DD/MM/YYYY

    // D = YYYY-MM-DD
    // DT = YYYY-MM-DD HH:mm:ss

    let ext = ''
    if (format.length <= 2) {
        if (format.length === 2) {
            const t = format[1]
            if (t === 'T') {
                ext = _fdate(d, ' HH:mm:ss')
            }
            else if (t === 't') {
                ext = _fdate(d, ' HH:mm')
            }
        }
        const fixFormat = format[0]
        if (locale === 'th') {
            const year = d.getFullYear() + 543
            if (fixFormat === 'L') return `${_fdate(d, 'EEEE d MMMM')} ${year}${ext ? ' เวลา' + ext : ''}`
            if (fixFormat === 'M') return `${_fdate(d, 'd MMMM')} ${year}${ext ? ' ,' + ext : ''}`
            if (fixFormat === 'S') return `${_fdate(d, 'd MMM')} ${year.toString().substr(2)}${ext ? ' ,' + ext : ''}`
            if (fixFormat === 'P') return `${_fdate(d, 'dd/MM/')}${year}${ext}`
            if (fixFormat === 'Y') return year
            if (fixFormat === 'Z') return `${_fdate(d, 'MMMM')} ${year}`
        }

        if (fixFormat === 'T') return _fdate(d, 'HH:mm:ss')
        if (fixFormat === 't') return _fdate(d, 'HH:mm')

        if (fixFormat === 'D') return _fdate(d, `yyyy-MM-dd${ext}`)
        if (fixFormat === 'P') return _fdate(d, `DD/MM/yyyy${ext}`)
        if (fixFormat === 'S') return _fdate(d, `d MMM yy${ext}`)
        if (fixFormat === 'M') return _fdate(d, `d MMM yyyy${ext ? ' , ' + ext : ''}`)
        if (fixFormat === 'L') return _fdate(d, `d MMMM yyyy${ext ? ' , ' + ext : ''}`)
        if (fixFormat === 'Z') return _fdate(d, 'MMMM yyyy')
    }
    return _fdate(d, format)
}

const _fdate = (date: any, format: string) => {
    return dFormat(date, format, { locale: dateLocale() })
}