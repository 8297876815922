import { DeviceMaList } from './pages/modules/ma'

export const ThemeMenu = [
    { icon: 'paint-brush', key: 'app', name: 'Standard' },
    { icon: 'moon-cloud', key: 'dark', name: 'Dark mode (beta)' },
    //{ icon: 'palette', key: 'color', name: 'Coloful' },
]

export const LangMenu = [
    { key: 'th', text: 'Thai (ไทย)', short: 'ไทย' },
    { key: 'en', text: 'English', short: 'Eng' },
    { key: 'ja', text: 'Japanese (日本語)', short: '日本語' },
    { key: 'lo', text: 'Laos (ລາວ)', short: 'ລາວ' },
    { key: 'zh', text: 'China (中國)', short: '中國' },
]

export const MainMenu = [
    {
        name: 'monitor',
        items: [
            { name: 'tracking', icon: 'map-marked-alt', text: 'MENU_TRACKING', url: '/tracking' },
            //{ name: 'tracking_mobile', icon: 'mobile-android', text: 'MENU_MOBILE', url: '/m' },
            { name: 'controlroom', icon: 'camcorder', text: 'MENU_CONTROLROOM', url: '/controlroom', role: '_controlroom' },
        ]
    },
    {
        name: 'map',
        items: [
            { name: 'device', icon: 'router', text: 'USER_MENU_DEVICE', url: '/user/device', role: 'device_manage' },
            { name: 'notification', icon: 'bell-on', text: 'USER_MENU_NOTIFICATION', url: '/user/notification', role: 'tracking_notify' },
            { name: 'command', icon: 'paper-plane', text: 'USER_MENU_COMMAND', url: '/user/command', role: 'tool_command' },
            { name: 'poi', icon: 'map-marker-alt', text: 'USER_MENU_POI', url: '/user/poi', role: 'poi_create' },
            { name: 'area', icon: 'vector-square', text: 'USER_MENU_AREA', url: '/user/area', role: 'poi_create' },
        ],
    },
    {
        name: 'office',
        items: [
            { name: 'employee', icon: 'user-astronaut', text: 'USER_MENU_EMPLOYEE', url: '/user/employee', role: 'driver_manage' },
            { name: 'employee_day', icon: 'calendar-alt', text: 'USER_MENU_EMPLOYEE_DAY', url: '/user/employee_day', role: 'driver_manage' },
        ]
    },
    {
        name: 'service',
        items: [
            { name: 'ma', icon: 'wrench', text: 'MENU_MODULE_MA', url: '/ma', role: 'ma' },
            { name: 'ma_service', icon: 'tools', text: 'MA_MENU_SERVICE', url: '/ma_service', role: 'ma' },
            { name: 'tms', icon: 'shipping-fast', text: 'MENU_MODULE_TMS', url: '/tms', role: 'tms' },
            { name: 'revenue', icon: 'chart-line', text: 'MENU_MODULE_REVENUE', url: '/revenue', role: 'revenue' },
            { name: 'tmsroute', icon: 'chart-network', text: 'MENU_MODULE_TMSROUTE', url: '/tmsroute', role: 'tmsroute' },
            { name: 'tool_distance', icon: 'map-signs', text: 'MENU_TOOL_POI', url: '/tools/distance', role: 'tool_distance' },
            { name: 'fuel', icon: 'gas-pump', text: 'USER_MENU_FUEL', url: '/user/fuel', role: 'tool_fuel' },
            { name: 'flashview', icon: 'street-view', text: 'USER_MENU_FLASHVIEW', url: '/user/flashview', role: 'tool_flashview_manage' },
        ],
    },
    {
        name: 'user',
        items: [
            { name: 'dashboard', icon: 'laptop-house', text: 'Dashboard', url: '/dashboard', role: '__dev' },
            { name: 'report', icon: 'book', text: 'USER_MENU_REPORT', url: '/user/report', urlMatch: '/report', role: 'report' },
            { name: 'profile', icon: 'user-circle', text: 'USER_MENU_PROFILE', url: '/user/profile' },
            { name: 'subuser', icon: 'user-friends', text: 'USER_MENU_SUBUSER', url: '/user/subuser', role: 'subuser' },
            { name: 'log', icon: 'history', text: 'USER_MENU_LOG', url: '/user/log' },
            { name: 'support', icon: 'info-circle', text: 'MENU_HELP', url: '/support', role: 'support' },
            { name: 'dev', icon: 'laptop-code', text: 'Develop', url: '/test/dev', role: '__dev' },
        ],
    },
    /*
    {
        items: [
            // { name: 'checkpoint', icon: 'briefcase', text: 'MENU_MODULE_CHECKPOINT', url: '/checkpoint', role: 'checkpoint' },
            { name: 'checkpoint', icon: 'tablet-rugged', text: 'USER_MENU_CHECKPOINT', url: '/user/checkpoint', role: 'checkpoint' },
        ]
    },*/
]

/////////////////////////////////

const CheckpointMenu = [
    {
        name: 'core', items: [
            { name: 'job', icon: 'business-time', text: 'รายการงาน', url: '/checkpoint/job' },
        ]
    },
]

const ModuleMaMenu = [
    {
        name: 'core', items: [
            { name: 'summary', icon: 'analytics', text: 'MA_MENU_SUMMARY', url: '/ma/summary' },
            { name: 'template', icon: 'clipboard', text: 'MA_MENU_TEMPLATE', url: '/ma/template' },
        ]
    },
]

const ModuleTmsMenu = [
    {
        name: 'core', items: [
            { name: 'summary', icon: 'calendar-alt', text: 'TMS_MENU_CALENDAR', url: '/tms/calendar' },
            { name: 'list', icon: 'list', text: 'TMS_MENU_LIST', url: '/tms/list' },
            { name: 'search', icon: 'search', text: 'TMS_MENU_SEARCH', url: '/tms/search' },
        ]
    },
    {
        name: 'meta', items: [
            { name: 'company', icon: 'building', text: 'USER_MENU_COMPANY', url: '/tms/company' },
            { name: 'customer', icon: 'users', text: 'USER_MENU_CUSTOMER', url: '/tms/customer' },
        ]
    },
    {
        name: 'setting', items: [
            { name: 'payment', icon: 'cog', text: 'MENU_SETTING', url: '/tms/setting' }
        ]
    },
]

export function getMenu (cPath: string) {
    //if (cPath.startsWith('/user/')) return UserMenu
    if (cPath.startsWith('/checkpoint/')) return CheckpointMenu

    if (cPath.startsWith('/ma/')) return ModuleMaMenu
    if (cPath.startsWith('/tms/')) return ModuleTmsMenu

    return null
}

export function getSideExtension (cPath: string) {
    if (cPath.startsWith('/ma/'))
        return <DeviceMaList/>
    return null
}
