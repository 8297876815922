import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, createPaging, Icon, IconDelete, List, ListBody, ListButton, ListContainer, ListHead, Modal, PageTitle, Paging } from 'unno-comutils/ui'
import { dConfirm, debounce, get, post, tError, tSuccess } from 'unno-comutils'
import { Checkbox, Input, InputSearch, Select } from 'unno-comutils/form'
import Dropdown, { DropdownItem } from 'unno-comutils/Dropdown'

import { MA_MODEs } from '../../var.meta'
import { IconNew } from '../../components/common'
import { MA_ITEM_NAMEs } from '../../var'
import { filterSave, hhash, hhashRemove, hhashUpdate, iconModal, tdate } from '../../utils'

export const DEFAULT_TEMPLATE = () => ({ ignore: true, key: Math.random(), name: '', mode: 10, interval: 0, isSms: false, isEmail: false })

export function Template () {
    const { t } = useTranslation()

    const [datas, setDatas] = useState<any>(null)

    const [form, setForm] = useState<any>(null)
    const [formUseToDevice, setFormUseToDevice] = useState<any>(null)

    const [search, setSearch] = useState('')

    const [paging, setPaging] = useState(createPaging(1))

    // ----- ACTION

    const loadList = debounce((p?: any) => {
        if (!p) p = paging
        const params = { search, page: p?.page || 1 }
        get('ma/template', params).then(d => {
            if (d.ok) {
                setDatas(d.datas)
                setPaging(d.paging)
            }
        })
    }, 300)

    const deleteData = (id: any) => {
        dConfirm(t('CONFIRM_DELETE')).then(() => {
            post('ma/template_drop/' + id, null).then(d => {
                if (d.ok) {
                    tSuccess(t('INFO_DROPED'))
                    loadList()
                }
            })
        })
    }

    // ----- MEMO

    useEffect(() => {
        const h = hhash()
        if (h && h.type === 'template') setForm(h.id)
    }, [])

    useEffect(() => {
        loadList()
    }, [search])

    // ----- RENDER

    return <>
        <PageTitle icon="clipboard" title={t('MA_MENU_TEMPLATE')}>
            <InputSearch value={search} onChange={setSearch}/>
            <Button className="ml-3" success onClick={() => setForm(0)}>{t('BUTTON_ADD')}</Button>
        </PageTitle>

        <ListContainer fill>
            <ListHead>
                <div className="w-rownum c grey-blue">#</div>
                <div className="w-fill">{t('TEXT_NAME')}</div>
                <div className="w-number c">{t('TEXT_LIST')}</div>
                <div className="w-date-st r">{t('TEXT_UPDATETIME')}</div>
                <div className="w-20"/>
                <div className="w-icon"/>
            </ListHead>
            <ListBody scroll>
                {!!datas && datas.map((d: any) => <List key={'i' + d.id}>
                    <ListButton fill onClick={() => setForm(d.id)}>
                        <div className="w-rownum c grey-blue">{d._rownum}</div>
                        <div className="w-fill">{d.name}</div>
                        <div className="w-number c">{d.count}</div>
                        <div className="w-date-st r grey-blue">{tdate(t, d.last, 'St')}</div>
                    </ListButton>
                    <Button className={'w-20'} success onClick={() => setFormUseToDevice(d.id)}>{t('TEXT_APPLY')}</Button>
                    <IconDelete onClick={() => deleteData(d.id)}/>
                </List>)}
            </ListBody>
        </ListContainer>

        <Paging paging={paging} onChange={loadList}/>
        <TemplateForm id={form} onSave={loadList} onClose={() => setForm(null)}/>
        <TemplateUsetoDeviceForm id={formUseToDevice} onSave={loadList} onClose={() => setFormUseToDevice(null)}/>
    </>
}

export function TemplateLoad (props: any) {
    const { t } = useTranslation()
    const [template, setTemplate] = useState<any>(null)
    const [isDrop, setIsDrop] = useState(false)

    // ----- ACTION

    const saveData = (c: any) => {
        if (template) {
            get('ma/template_load/' + template.id).then(d => {
                if (d.ok) {
                    const items = d.data.items.map((d: any) => ({ ...d, id: 0 })) || []
                    items.forEach((d: any) => props.datas.push(d))
                    props.setDatas(isDrop ? items : props.datas)
                    props.onClose()
                }
            }).finally(c)
        }
        else c()
    }

    // ----- ACTION

    const confirmSave = (c: any) => {
        if (template) {
            if (isDrop) dConfirm('ข้อมูลเดิมจะถูกลบทั้งหมด !?').then(() => saveData(c)).finally(c)
            else saveData(c)
        }
        else c()
    }

    const onClose = () => {
        setTemplate(null)
        setIsDrop(false)
    }

    // ----- RENDER

    return <Modal title={t('BUTTON_TEMPLATE_LOAD')} open={!!props.open} sm onClose={props.onClose} onCloseEnd={onClose} footerSave={confirmSave}>
        <Select m0 label={t('MA_MENU_TEMPLATE')} value={template} url={'ac/template'} noClear onChange={(_, v) => setTemplate(v)}/>
        <Checkbox m0 text={t('TEXT_CLEAR_OLD')} checked={isDrop} onChange={() => setIsDrop(!isDrop)}/>
    </Modal>
}

export function TemplateForm (props: any) {
    const { t } = useTranslation()

    const [data, setData] = useState<any>(null)

    // ----- ACTION

    const loadData = () => {
        if (props.id > 0) {
            get('ma/template_load/' + props.id).then(d => {
                if (d.ok) {
                    const items = d.data.items.map((d2: any) => ({ ...d2, key: Math.random() })) || []
                    setData({ ...d.data, items: [...items, DEFAULT_TEMPLATE()] })
                    hhashUpdate('template,' + d.data.id)
                }
            })
        }
        else {
            setData({ id: 0, name: '', items: props.items ? [...props.items, DEFAULT_TEMPLATE()] : [DEFAULT_TEMPLATE()] })
        }
    }

    const saveData = (c: any) => {
        const items = filterSave(data.items).map((d: any) => ({ ...d, mode: d.mode?.id }))
        post('ma/template_save/' + data.id, { ...data, items }).then(d => {
            if (d.ok) {
                tSuccess(t('INFO_SAVED'))
                props.onSave()
                props.onClose()
            }
        }).finally(c)
    }

    const deleteData = () => {
        dConfirm(t('CONFIRM_DELETE')).then(() => {
            get('ma/template_drop/' + props.id).then(d => {
                if (d.ok) {
                    tSuccess(t('INFO_DROPED'))
                    props.onSave()
                    props.onClose()
                }
            })
        })
    }

    // ----- EVENT

    const onChange = (v: any) => setData({ ...data, ...v })

    const onItemChange = (update: any, index: any) => {
        let items = data.items
        items[index] = { ...items[index], ...update }
        if (items[index].ignore) {
            items[index].ignore = false
            items.push(DEFAULT_TEMPLATE())
        }
        onChange({ items })
    }

    const onItemDrop = (key: any) => onChange({ items: data.items.filter((d: any) => d.key !== key) })

    const onClose = () => {
        hhashRemove()
        setData(null)
    }

    // ----- MEMO

    const t_MA_MODEs = useMemo(() => MA_MODEs.map((d: any) => ({ ...d, name: t(d.name), unit: t(d.unit) })), [t])

    // ----- RENDER

    return <Modal icon={iconModal(props.id)} title={t('MA_MENU_TEMPLATE')} md open={props.id != null} onClose={props.onClose}
                  onOpenEnd={loadData} onCloseEnd={() => onClose()} footerSave={saveData} footerDrop={props.id > 0 && deleteData}>
        {data && <>
            <Input label="ชื่อแม่แบบ" className="mt-4" value={data.name} onChange={name => onChange({ name })}/>
            {props.id >= 0 && <ListContainer>
                <ListHead>
                    <div className="w-rn c grey-blue">#</div>
                    <div className="w-fill">{t('TEXT_DETAIL')}</div>
                    <div className="w-52 c">{t('MA_TEXT_MODE')}</div>
                    <div className="w-32 c">{t('MA_TEXT_INTERVAL')}</div>
                    <div className="w-icon3 c">{t('TEXT_NOTIFY')}</div>
                    <div className="w-icon"/>
                </ListHead>
                <ListBody>
                    {!!data.items && data.items.map((d: any, index: any) => <List key={'i_' + index} center>
                        <IconNew index={index} ignore={d.ignore}/>
                        <MaInputName value={d.name} onChange={name => onItemChange({ name }, index)}/>
                        <Select className="w-52" value={d.mode?.id} options={t_MA_MODEs} noClear onChange={(v, mode) => onItemChange({ mode }, index)}/>
                        <Input className="w-32" type="number" right unit={t_MA_MODEs.find((_d: any) => _d.id === d.mode?.id)?.unit || '-'} value={d.pointInterval}
                               onChange={pointInterval => onItemChange({ pointInterval }, index)}/>
                        <Icon button name={'sms'} solid className={d.isSms ? 'orange' : 'grey'} tooltip={'SMS'} onClick={() => onItemChange({ isSms: !d.isSms }, index)}/>
                        <Icon button name={'envelope'} solid className={d.isEmail ? 'blue' : 'grey'} tooltip={'E-Mail'}
                              onClick={() => onItemChange({ isEmail: !d.isEmail }, index)}/>
                        <Icon button name={'line'} className={d.isLine ? 'green' : 'grey'} tooltip={'Line'} brand onClick={() => onItemChange({ isLine: !d.isLine }, index)}/>
                        {d.ignore ? <div className="w-icon"/> : <IconDelete onClick={() => onItemDrop(d.key)} tooltip={'ลบทิ้ง'}/>}
                    </List>)}
                </ListBody>
            </ListContainer>}
        </>}
    </Modal>
}

export function TemplateUsetoDeviceForm (props: any) {
    const { t } = useTranslation()

    const [devices, setDevices] = useState<any>([])
    const [clear, setClear] = useState(0)

    // ----- ACTION

    const loadData = () => {
        get('ma/template_devices').then((d: any) => {
            if (d.ok) {
                setDevices(d.datas)
            }
        })
    }

    const confirmSave = (c: any) => {
        if (deviceSelect.length === 0) return c(tError(t('TEXT_ALERT_SELECT_DEVICE')))
        if (clear) dConfirm('ข้อมูลเดิมจะถูกลบทั้งหมด !?').then(() => saveData(c)).finally(c)
        else saveData(c)
    }

    const saveData = (c: any) => {
        const saveData = {
            clear: clear ? 1 : 0,
            devices: deviceSelect.map((d: any) => d.id)
        }
        post('ma/template_useto/' + props.id, saveData).then((d: any) => {
            if (d.ok) {
                tSuccess(t('INFO_SAVED'))
                props.onSave()
                props.onClose()
            }
        }).finally(c)
    }

    // ----- EVENT

    const onChangeAll = (update: any) => setDevices((prev: any) => prev.map((d: any) => ({ ...d, ...update })))

    const onChange = (id: any, update: any) => setDevices((prev: any) => prev.map((d: any) => d.id === id ? ({ ...d, ...update }) : d))

    const onClose = () => {
        setDevices([])
        setClear(0)
    }

    // ----- MEMO

    const deviceSelect = useMemo(() => {
        return devices.filter((d: any) => d.isSelect)
    }, [devices])

    // ----- RENDER

    return <Modal title={t('MA_MENU_TEMPLATE')} open={props.id != null} onClose={() => props.onClose()}
                  onOpenEnd={loadData} onCloseEnd={onClose} footerSave={confirmSave} footer={<Checkbox checked={clear} text={t('TEXT_CLEAR_OLD')} onChange={(v) => setClear(v)}/>}>
        <ListContainer>
            <ListHead>
                <Checkbox checked={deviceSelect.length === devices.length} onChange={v => onChangeAll({ isSelect: v })}/>
                <div className={'w-fill'}>{t('TEXT_DEVICE')}</div>
                <div className={'w-20 c'}>{t('TEXT_LIST')}</div>
            </ListHead>
            <ListBody>
                {devices && devices.map((d: any) => <List key={'item_' + d.id}>
                    <Checkbox checked={d.isSelect} onChange={isSelect => onChange(d.id, { isSelect })}/>
                    <div className={'w-fill'}>{d.name}</div>
                    <div className={'w-20 c'}>{d.count > 0 ? d.count : '-'}</div>
                </List>)}
            </ListBody>
        </ListContainer>
    </Modal>
}

export function MaInputName (props: { value: string, onChange: (v: string) => void }) {
    return <Input className={'w-fill'}
                  apppend={<Dropdown zTop right button={<Icon button name={'angle-down'}/>}>
                      {MA_ITEM_NAMEs.map(s => <DropdownItem key={'s_' + s} label={s} onClick={() => props.onChange(s)}/>)}
                  </Dropdown>}
                  value={props.value || ''} onChange={props.onChange}/>
}

