import React, { useEffect, useMemo, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

import { closestCenter, DndContext } from '@dnd-kit/core'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { clsNames, dbdate, dConfirm, get, linkClick, num, post, tError, tSuccess } from 'unno-comutils'
import { Button, createPaging, Icon, IconDelete, List, ListBody, ListButton, ListContainer, ListFoot, ListHead, Menu, Modal, PageTitle, Paging, Wait } from 'unno-comutils/ui'
import { Checkbox, Input, InputDate, InputSearch, InputSearchRow, Radio, Select, Upload } from 'unno-comutils/form'
import { date } from 'unno-comutils/utils'

import { filterSave, iconModal, loadMeta, tdate, timeDuration } from '../../utils'
import { DetailItem, IconNew, MockEmpty } from '../../components/common'
import { MA_MODEs } from '../../var.meta'
import { MaInputName, Template, TemplateForm, TemplateLoad } from './maTemplate'

export const DEFAULT_MA = () => ({ id: 0, name: '', mode: 10, interval: '', current: '', isSms: false, isEmail: false, isLine: false })

export const DEFAULT_SERVICE = () => ({ id: 0, km: 0, garage: '', note: '', start: new Date(), end: new Date(), datas: [DEFAULT_SERVICE_ITEM()] })
export const DEFAULT_SERVICE_ITEM = () => ({ ignore: true, key: Math.random(), detail: '', count: 0, price: 0 })

const DEVICE_MENUs: any = [
    { page: 'info', name: 'MA_MENU_LIST', icon: 'bell-on' },
    { page: 'history', name: 'MA_MENU_HISTORY', icon: 'history' },
    // { page: 'form', name: 'MA_MENU_FORM', icon: 'pencil-alt' },
    { page: 'service', name: 'MA_MENU_SERVICE', icon: 'toolbox' },
]

export default function Ma () {
    return <div className="page-content">
        <Switch>
            <Route exact path="/ma"><Redirect to="/ma/summary"/></Route>
            <Route exact path="/ma/summary"><MaSummary/></Route>
            <Route exact path="/ma/template"><Template/></Route>
            <Route exact path={['/ma/device/:id', '/ma/device/:id/:tab']}><DeviceMa/></Route>
        </Switch>
    </div>
}

// ------ MA SUMMARY -----

function MaSummary () {
    const { t } = useTranslation()

    const [datas, setDatas] = useState<any>(null)
    const [fromView, setFromView] = useState<any>(null)

    // ----- ACTION

    const loadList = () => {
        get('ma/summary', { group: 1 }).then(d => d.ok && setDatas(d.datas))
    }

    const resetMa = (device: any, maId: any) => {
        if (device && maId)
            dConfirm(t('CONFIRM_RESET')).then(() => post('ma/reset/' + device, { id: maId }).then(d => d.ok && loadList()))
    }

    // ----- MEMO

    useEffect(() => {
        loadList()
    }, [])

    // ----- RENDER

    return <>
        <PageTitle icon="analytics" title={t('MA_MENU_SUMMARY')}/>
        <ListContainer fill>
            <ListHead>
                <div className="w-12 c">{t('TEXT_SENDED')}</div>
                <div className="w-64">{t('TEXT_DEVICE')}</div>
                <div className="w-fill">{t('TEXT_DETAIL')}</div>
                <div className="w-32 r">{t('MA_TEXT_MODE')}</div>
                <div className="w-24 c">{t('MA_TEXT_INTERVAL')}</div>
                <div className="w-24 c">{t('MA_TEXT_CURRENT')}</div>
                <div className="w-32"/>
                <div className="w-icon3 c">{t('TEXT_NOTIFY')}</div>
                <div className="w-16"/>
            </ListHead>
            <ListBody scroll>
                {datas && datas.map((data: any, index: any) => <div key={`list-${index}`}>
                    <div className="table-device-group">{data.name}</div>
                    <div className={'list-body'}>
                        {data.devices.map((d: any) => <List key={'list_' + d.id}>
                            <div className="w-12 c pb-0">{d.sended ? <Icon name={'rss orange'} solid/> : <Icon name={'circle grey'}/>}</div>
                            <div className="w-64 text-overflow">{d.device.name}</div>
                            <div className="w-fill text-overflow">{d.name}</div>
                            <div className="w-32 r grey-blue">{t(d.mode?.name)}</div>
                            <div className="w-24 blue c">{num(d.pointInterval, 0) + ' ' + t(d.mode?.unit)}</div>
                            <div className="w-24 green c link-click" onClick={() => setFromView(d.id)}> {num(d.pointCurrent, 0) + ' ' + t(d.mode?.unit)}</div>
                            <div className="w-32 red">({t('MA_TEXT_REMAIN', { value: num(d.pointRemain, 0), unit: t(d.mode?.unit) })})</div>
                            {d.isSms ? <Icon button name={'mobile-alt orange'} solid tooltip={t('TEXT_SMS')}/> : <div className="w-icon"/>}
                            {d.isEmail ? <Icon button name={'envelope blue'} solid tooltip={t('TEXT_EMAIL')}/> : <div className="w-icon"/>}
                            {d.isLine ? <Icon button name={'line green'} brand tooltip={t('TEXT_LINE')}/> : <div className="w-icon"/>}
                            {d.pointNear
                                ? <Button success className="w-16" onClick={() => resetMa(d.device?.id, d.id)}>{t('BUTTON_RESET')}</Button>
                                : <div className="w-16"/>}
                        </List>)}
                    </div>
                </div>)}
            </ListBody>
        </ListContainer>
        <MaSummaryView id={fromView} onClose={() => setFromView(null)}/>
    </>
}

function MaSummaryView (props: any) {
    const { t } = useTranslation()

    const [data, setData] = useState<any>(null)
    const [summarys, setSummarys] = useState<any>([])

    // ----- ACTION

    const loadData = () => {
        get('ma/view/' + props.id).then(d => {
            if (d.ok) {
                setData(d.data)
                setSummarys(d.summarys)
            }
        })
    }

    // ----- EVENT

    const onClose = () => {
        setData(null)
        setSummarys([])
    }

    // ----- MEMO

    const sum = useMemo(() => {
        if (summarys && summarys.length > 0)
            return summarys.reduce((s: any, o: any) => {
                s.duration += o.duration
                s.length += o.length
                return s
            }, { duration: 0, length: 0 })
        return { duration: 0, length: 0 }
    }, [summarys])

    // ----- RENDER

    return <Modal title={data ? `${data.device?.name} : ${data.name}` : '?'} open={props.id !== null} onClose={() => props.onClose()}
                  onOpenEnd={loadData} onCloseEnd={onClose}>

        {data && <div className="row">
            <div className="col w-1/2">
                <DetailItem label={t('MA_TEXT_MODE')}>{t(data.mode?.name)}</DetailItem>
                <DetailItem label={t('MA_TEXT_INTERVAL')}>{num(data.pointInterval, 0) + ' ' + t(data.mode?.unit)}</DetailItem>
                <DetailItem label={t('MA_TEXT_CURRENT')}>{num(data.pointCurrent, 0) + ' ' + t(data.mode?.unit)}</DetailItem>
                <DetailItem className={'grey'}>{t('MA_TEXT_REMAIN', { value: num(data.pointRemain, 0), unit: t(data.mode?.unit) })}</DetailItem>
                <hr/>
                <DetailItem label="Point Start">{num(data.pointStart, 0)}</DetailItem>
                <DetailItem label="Point Date">{date(data.pointDate, 'S')}</DetailItem>
            </div>

            <div className="col w-1/2">
                <ListContainer height={300}>
                    <ListHead>
                        <div className={'w-fill'}>{t('TEXT_DATE')}</div>
                        <div className={'w-16 r'}>{t('TEXT_DURATION')}</div>
                        <div className={'w-16 r'}>Length</div>
                    </ListHead>
                    <ListBody scroll>
                        {summarys.map((d: any, index: any) => <List key={'item_' + index}>
                            <div className={'w-fill'}>{date(d.date, 'S')}</div>
                            <div className={'w-16 r green'}>{timeDuration(d.duration)}</div>
                            <div className={'w-16 r red'}>{num(d.length, 2)}</div>
                        </List>)}
                    </ListBody>
                    <ListFoot>
                        <div className={'w-fill'}>{t('TEXT_TOTAL')} ({summarys?.length || 0} {t('UNIT_DAY')})</div>
                        <div className={'w-16 r green'}>{timeDuration(sum.duration)}</div>
                        <div className={'w-16 r red'}>{num(sum.length, 2)}</div>
                    </ListFoot>
                </ListContainer>
            </div>
        </div>}
    </Modal>

}

// ------ MA DEVICE ------

export function DeviceMaList () {
    const { pathname } = useLocation()
    const history = useHistory()

    const [wait, setWait] = useState(true)
    const [search, setSearch] = useState('')

    const [datas, setDatas] = useState<any>(null)

    const loadList = (page?: any) => {
        setWait(true)
        get('device/gets', { page: page?.page || 1, search, noAddress: 1 }).then(d => {
            if (d.ok) {
                setDatas([...d.devices])
                setWait(false)
            }
        })
    }

    useEffect(() => {
        loadList()
    }, [search])

    return <div className="_no-mini border page-content mt-4 p-1">
        <Input icon={'search'} sm m0 defValue={search} onDelayChange={v => setSearch(v)}/>
        <ListContainer fill className="mt-2">
            <ListBody scroll wait={wait}>
                {datas && datas.map((data: any) => <a key={'list_' + data.id} className={clsNames('ma-list', pathname.includes('/' + data.id) && '-active b')}
                                                      href={'/ma/device/' + data.id}
                                                      onClick={e => linkClick(e, history)}>{data.name}</a>)}
            </ListBody>
        </ListContainer>
    </div>
}

function DeviceMa () {

    const history = useHistory()

    const param: any = useParams()
    const { t } = useTranslation()

    const [tab, setTab] = useState(param?.tab || 'info')

    const [wait, setWait] = useState(true)

    const [device, setDevice] = useState<any>(null)

    const [datas, setDatas] = useState<any>([])

    const [meta, setMeta] = useState<any>(null)

    const [search, setSearch] = useState<any>('')
    const [searchMode, setSearchMode] = useState<any>(null)

    const [formMa, setFormMa] = useState<any>(null)
    const [formService, setFormService] = useState<any>(null)

    // ----- ACTION

    const loadDevice = () => {
        get('device/get/' + param.id).then(d => {
            if (d.ok) setDevice(d.device)
        })
    }

    const loadList = () => {
        setWait(true)
        get('ma/list/' + param.id).then(d => {
            if (d.ok) {
                setDatas(d.datas)
            }
        }).finally(() => setWait(false))
    }

    // ----- MEMO

    useEffect(() => {
        loadMeta({ user_notify: 1 }, setMeta)
    }, [])

    useEffect(() => {
        setDatas([])
        loadDevice()
    }, [param.id])

    useEffect(() => {
        loadList()
    }, [param.id])

    useEffect(() => {
        setFormService(null)
        history.push('/ma/device/' + param.id + '/' + tab)
    }, [tab])

    const tags = useMemo(() => {
        const tags: any = []
        if (searchMode) tags.push({ text: (searchMode?.name), color: 'green', onClear: () => setSearchMode(null) })
        return tags
    }, [searchMode])

    const t_MA_MODEs = useMemo(() => MA_MODEs.map((d: any) => ({ ...d, name: t(d.name), unit: t(d.unit) })), [t])

    // ----- RENDER

    if (!device) return <Wait/>

    return <>
        <PageTitle icon="router blue" title={device.name}>
            <Menu layout={'tab'} className={tab === 'info' ? 'mr-auto' : ''} items={DEVICE_MENUs.map((m: any) => ({ ...m, name: t(m.name) }))} value={tab} onChange={setTab}/>
            {tab === 'info' && <Button secondary className={'ml-3'} onClick={() => setFormMa({ id: device.id, name: device.name })}>{t('BUTTON_EDIT')}</Button>}
            {tab === 'history' && <>
                <InputSearch tags={tags} value={search} onChange={setSearch}>
                    <InputSearchRow label={'โหมด'} children={<Select options={t_MA_MODEs} value={searchMode} onChange={(_, v) => setSearchMode(v)}/>}/>
                </InputSearch>
                <Button className={'ml-2'} success href={''} targetBlank>{t('REPORT')}</Button>
            </>}
            {tab === 'service' && <>
                <Button success className="ml-auto" onClick={() => setFormService(0)}>{t('BUTTON_ADD')}</Button>
            </>}
        </PageTitle>

        {(() => {
            if (tab === 'info') return <DeviceInfo wait={wait} datas={datas} onSave={loadList} id={param.id}/>
            if (tab === 'history') return <DeviceHistory id={param.id} search={search} searchMode={searchMode?.id}/>
            if (tab === 'service') return <DeviceService device={device.id} service={formService} onSave={() => setFormService(null)} onClose={() => setFormService(null)}/>
        })()}

        <DeviceMaForm device={formMa} meta={meta} onSave={loadList} onClose={() => setFormMa(null)}/>
    </>
}

function DeviceMaForm (props: any) {
    const { t } = useTranslation()
    const { meta } = props

    const [datas, setDatas] = useState<any>([])
    const [notifyId, setNotifyId] = useState(0)

    const [formCopyTemplate, setFormCopyTemplate] = useState<any>(null)     // เรียกจากแม่แบบ
    const [formCopy, setFormCopy] = useState<any>(null)
    const [formCreateTemplate, setFormCreateTemplate] = useState<any>(null) // บันทึกลงแม่แบบ

    // ----- ACTION

    const loadData = () => {
        if (props.device) {
            get('ma/list/' + props.device.id).then(d => {
                if (d.ok) setDatas(d.datas)
            })
            get('user/device/get/' + props.device.id).then((d: any) => {
                if (d.ok) setNotifyId(d.device.notifyId)
            })
        }
    }

    const saveData = (c: any) => {
        let error = ''
        const saveData = {
            datas: datas.map((d: any, index: any) => {
                if (!d.mode || !d.name || !d.pointInterval && !error) error = t('INFO_SAVE_DATA_INCOMPLETE')
                return ({ ...d, order: index, mode: d.mode?.id })
            }),
            notifyId
        }

        if (!error) {
            post('ma/save/' + props.device.id, saveData).then(d => {
                if (d.ok) {
                    tSuccess(t('INFO_SAVED'))
                    props.onSave()
                    props.onClose()
                }
            }).finally(c)
        }
        else c(tError(error))
    }

    // ----- EVENT

    const onDragEnd = (event: any) => {
        const { active, over } = event
        if (active.id !== over.id) {
            const oldIndex = datas.findIndex((f: any) => f.id === active.id)
            const newIndex = datas.findIndex((f: any) => f.id === over.id)
            setDatas((prev: any) => arrayMove(prev, oldIndex, newIndex))
        }
    }

    const onItemAdd = () => setDatas([...datas, DEFAULT_MA()])

    const onItemChange = (update: any, index: any) => setDatas(datas.map((d: any, i: number) => i === index ? { ...d, ...update } : d))

    const onItemDelete = (index: any) => setDatas(datas.filter((_: any, i: number) => i !== index))

    const onClose = () => {
        setDatas([])
        setNotifyId(0)
    }

    // ----- MEMO

    const hasChNotify = useMemo(() => meta?.USER_NOTIFYs && meta?.USER_NOTIFYs.length > 0, [meta])

    // ----- RENDER

    const renderHead = <>
        <Button dark className="ml-2" onClick={() => setFormCopy(true)}>{t('TEXT_COPY')}</Button>
        <Button warning className="ml-2" onClick={() => setFormCopyTemplate(true)}>{t('BUTTON_TEMPLATE_LOAD')}</Button>
        <Button primary outline className="ml-2" onClick={() => setFormCreateTemplate(0)}>{t('BUTTON_TEMPLATE_SAVE')}</Button>
    </>

    const renderFootor = <>
        <Button success className="ml-2" onClick={() => onItemAdd()}>{t('BUTTON_ADD')}</Button>
        {hasChNotify && <div className="mx-4">{t('TEXT_NOTIFY_CHANNEL')}</div>}
        {hasChNotify &&
            <Select m0 className={'w-64'} placeholder={t('TEXT_NOTIFY_CHANNEL_MAIN')} options={meta?.USER_NOTIFYs || []} value={notifyId} onChange={setNotifyId}/>}
    </>

    return <Modal icon={'pencil-alt blue'} title={props.device?.name || ''} lg open={props.device !== null} onClose={() => props.onClose()}
                  header={renderHead} footer={renderFootor}
                  onOpenEnd={loadData} onCloseEnd={onClose} footerSave={saveData}>

        <ListContainer fill>
            <ListHead>
                <div className="w-icon"/>
                <div className="w-rn c">#</div>
                <div className="w-fill">{t('TEXT_DETAIL')}</div>
                <div className="w-52 c">{t('MA_TEXT_MODE')}</div>
                <div className="w-32 c">{t('MA_TEXT_INTERVAL')}</div>
                <div className="w-32 c">{t('MA_TEXT_CURRENT')}</div>
                <div className="w-64 c">{t('TEXT_NOTIFY')}</div>
                <div className="w-icon"/>
            </ListHead>
            <ListBody>
                {datas && datas.length > 0 ? <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
                    <SortableContext items={datas} strategy={verticalListSortingStrategy}>
                        {datas.map((ma: any, index: any) =>
                            <DeviceMaItem key={'item_' + index} ma={ma} index={index} onChange={onItemChange} onDelete={onItemDelete}/>)}
                    </SortableContext>
                </DndContext> : <MockEmpty/>}
            </ListBody>
        </ListContainer>

        <TemplateLoad open={formCopyTemplate} datas={datas} setDatas={setDatas} onClose={() => setFormCopyTemplate(null)}/>
        <TemplateForm id={formCreateTemplate} items={datas} onSave={() => setFormCreateTemplate(null)} onClose={() => setFormCreateTemplate(null)}/>
        <DeviceCopyForm open={formCopy} deviceId={props.id} onSave={loadData} onClose={() => setFormCopy(null)}/>
    </Modal>
}

function DeviceMaItem (props: { ma: any, index: any, onChange: any, onDelete: any }) {
    const { ma, index, onChange, onDelete } = props
    const { t } = useTranslation()

    const { attributes, listeners, setNodeRef, isDragging, transform, transition } = useSortable({ id: ma.id })

    const t_MA_MODEs = useMemo(() => MA_MODEs.map((d: any) => ({ ...d, name: t(d.name), unit: t(d.unit) })), [t])
    const unit = useMemo(() => t_MA_MODEs.find((_d: any) => _d.id === ma.mode?.id)?.unit || '', [ma])

    return <div ref={setNodeRef} style={{ transform: CSS.Transform.toString(transform) }} className={clsNames('un-list un-list-grid', isDragging && '-drag')}>
        <div {...attributes} {...listeners} className="w-icon _btn-move ml-3">
            <Icon name={'bars'} className="un-icon-move"/>
        </div>
        <div className="w-rn c grey-blue">{index + 1}</div>
        <MaInputName value={ma.name} onChange={name => onChange({ name }, index)}/>
        <Select className="w-52" value={ma.mode?.id} options={t_MA_MODEs} noClear onChange={(v, mode) => onChange({ mode }, index)}/>
        <Input className="w-32" type="number" right unit={unit} value={ma.pointInterval} onChange={pointInterval => onChange({ pointInterval }, index)}/>
        <Input className="w-32" type="number" right unit={unit} value={ma.pointCurrent} onChange={pointCurrent => onChange({ pointCurrent }, index)}/>
        <Checkbox text={'SMS'} checked={ma.isSms} onChange={() => onChange({ isSms: !ma.isSms }, index)}/>
        <Checkbox text={'E-Mail'} checked={ma.isEmail} onChange={() => onChange({ isEmail: !ma.isEmail }, index)}/>
        <Checkbox text={'Line'} checked={ma.isLine} onChange={() => onChange({ isLine: !ma.isLine }, index)}/>
        <IconDelete onClick={() => onDelete(index)}/>
    </div>
}

function DeviceCopyForm (props: { open: any, deviceId: number, onSave: () => void, onClose: () => void }) {
    const { t } = useTranslation()
    const [data, setData] = useState<any>(null)

    const saveData = (c: any) => {
        const saveData = {
            clear: data.clear ? 1 : 0,
            device: data.copy === 0 ? data.device?.id : props.deviceId,
            deviceTo: data.copy === 0 ? props.deviceId : data.device?.id,
        }
        post('ma/copy', saveData).then((d: any) => {
            if (d.ok) {
                tSuccess(t('INFO_SAVED'))
                props.onSave()
                props.onClose()
            }
            else tError(t('INFO_SAVE_FAIL'))
        }).finally(c)
    }

    const loadData = () => setData({ clear: false, copy: 0, device: null })

    const onChange = (update: any) => setData((prev: any) => ({ ...prev, ...update }))

    return <Modal icon={'copy'} sm title={t('TEXT_COPY')} open={props.open !== null} onClose={props.onClose} footerSave={saveData} onOpenEnd={loadData}>
        {data && <>
            <div className="flex-on mb-2">
                <Radio m0 value={data.copy} options={[{ id: 0, name: t('TEXT_COPY_FROM') }, { id: 1, name: t('TEXT_COPY_TO') }]} onChange={copy => onChange({ copy })}/>
                <Checkbox m0 className="ml-auto" text={t('TEXT_CLEAR_OLD')} checked={data.clear} onChange={c => onChange({ clear: c })}/>
            </div>
            <Select m0 label={t('TEXT_DEVICE')} url={'ac/device'} noClear value={data.device} onChange={(_, device) => onChange({ device })}/>
        </>}
    </Modal>
}

export function DeviceInfo (props: any) {
    const { datas, wait } = props
    const { t } = useTranslation()

    const [view, setView] = useState<any>(null)

    // ----- ACTION

    const resetData = (deviceId: any, maId: any) => {
        dConfirm('ยืนยันการ reset !?').then(() => {
            post('ma/reset/' + deviceId, { id: maId }).then(d => {
                if (d.ok) props.onSave()
            })
        })
    }

    // ----- RENDER

    return <>
        <ListContainer fill>
            <ListHead>
                <div className="w-rn c">#</div>
                <div className="w-fill">{t('TEXT_DETAIL')}</div>
                <div className="w-32 r">{t('MA_TEXT_MODE')}</div>
                <div className="w-24">{t('MA_TEXT_INTERVAL')}</div>
                <div className="w-24 r">{t('MA_TEXT_CURRENT')}</div>
                <div className="w-32"/>
                <div className="w-icon3 c">{t('TEXT_NOTIFY')}</div>
                <div className="w-16 c">{t('TEXT_SENDED')}</div>
                <div className="w-date-st">{t('TEXT_NOTIFY_LAST')}</div>
                <div className="w-16"/>
            </ListHead>
            <ListBody scroll wait={wait}>
                {datas && datas.map((d: any, index: number) => <List key={'item_' + index}>
                    <div className="w-rn c grey-blue">{index + 1}</div>
                    <div className="w-fill">{d.name}</div>
                    <div className="w-32 blue r">{t(d.mode?.name)}</div>
                    <div className="w-24 blue">{num(d.pointInterval, 0) + ' ' + t(d.mode?.unit)}</div>
                    <div className="w-24 green r link-click" onClick={() => setView(d.id)}>{num(d.pointCurrent, 0) + ' ' + t(d.mode?.unit)}</div>
                    <div className="w-32 red">({t('MA_TEXT_REMAIN', { value: num(d.pointRemain, 0), unit: t(d.mode?.unit) })})</div>

                    {d.isSms ? <Icon button name={'mobile-alt orange'} solid tooltip="SMS"/> : <div className="w-icon"/>}
                    {d.isEmail ? <Icon button name={'envelope blue'} solid tooltip="E-Mail"/> : <div className="w-icon"/>}
                    {d.isLine ? <Icon button name={'line green'} brand tooltip="Line"/> : <div className="w-icon"/>}

                    <Icon className={'w-16 c'} name={d.sended ? 'rss orange b' : 'circle grey'}/>
                    <div className="w-date-st">{tdate(t, d.sendTime, 'St')}</div>
                    {d.pointNear
                        ? <Button success className="w-16" onClick={() => resetData(d.device?.id || 0, d.id)}>{t('BUTTON_RESET')}</Button>
                        : <div className="w-16"/>}
                </List>)}
            </ListBody>
        </ListContainer>
        <MaSummaryView id={view} onClose={() => setView(null)}/>
    </>
}

export function DeviceHistory (props: any) {
    const { search, searchMode } = props

    const { t } = useTranslation()

    const [wait, setWait] = useState(true)

    const [datas, setDatas] = useState<any>(null)

    // ----- ACTION

    const loadList = () => {
        setWait(true)
        get('ma/history/' + props.id, { search, mode: searchMode }).then(d => d.ok && setDatas(d.datas)).finally(() => setWait(false))
    }

    // ----- MEMO

    useEffect(() => {
        loadList()
    }, [search, searchMode])

    // ----- RENDER

    return <ListContainer fill>
        <ListHead>
            <div className="w-rownum c">#</div>
            <div className="w-fill">{t('TEXT_DETAIL')}</div>
            <div className="w-32 r">{t('MA_TEXT_MODE')}</div>
            <div className="w-32 r">{t('MA_TEXT_INTERVAL')}</div>
            <div className="w-32 r">{t('TEXT_RESET')}</div>
            <div className="w-date-st r">{t('TEXT_ONTIME')}</div>
        </ListHead>
        <ListBody scroll wait={wait}>
            {datas && datas.map((d: any, index: any) => <List key={'i' + index}>
                <div className="w-rownum c grey-blue">{index + 1}</div>
                <div className="w-fill">{d.name}</div>
                <div className="w-32 grey-blue r">{t(d.mode.name)}</div>
                <div className="w-32 blue r">{num(d.due, 0) + ' ' + t(d.mode?.unit)}</div>
                <div className="w-32 green r">{num(d.refresh, 0) + ' ' + t(d.mode?.unit)}</div>
                <div className="w-date-st r grey-blue">{tdate(t, d.time, 'St')}</div>
            </List>)}
        </ListBody>
    </ListContainer>
}

export function DeviceService (props: any) {
    const { t } = useTranslation()

    const [wait, setWait] = useState(true)

    const [datas, setDatas] = useState<any>(null)
    const [form, setForm] = useState<any>(null)

    const [paging, setPaging] = useState(createPaging(1))

    // ----- ACTION

    const loadList = (p?: any) => {
        setWait(true)
        onClose()
        if (!p) p = paging
        const params = { device: props.device, page: p?.page || 1 }
        get('ma/service', params).then(d => {
            if (d.ok) {
                setDatas(d.datas)
                setPaging(d.paging)
            }
        }).finally(() => setWait(false))
    }

    // ----- EVENT

    const onClose = () => {
        setForm(null)
        props.onClose()
    }

    // ----- MEMO

    useEffect(() => {
        loadList()
    }, [props.device])

    useEffect(() => {
        if (props.service !== null) setForm(0)
    }, [props.service])

    // ----- RENDER

    return <>
        <ListContainer fill>
            <ListHead>
                <div className="w-rownum c">#</div>
                <div className="w-date c">{t('MA_TEXT_DATE_IN')}</div>
                <div className="w-date c">{t('MA_TEXT_DATE_OUT')}</div>
                <div className="w-fill">{t('MA_TEXT_GARAGE')}</div>
            </ListHead>
            <ListBody scroll wait={wait}>
                {datas && datas.map((d: any, index: any) => <List key={`item-${d.id}`}>
                    <ListButton fill onClick={() => setForm(d.id)}>
                        <div className="w-rownum grey-blue c">{index + 1}</div>
                        <div className="w-date red c">{tdate(t, d.start, 'S')}</div>
                        <div className="w-date green c">{tdate(t, d.end, 'S')}</div>
                        <div className="w-fill">{d.garage}</div>
                    </ListButton>
                </List>)}
            </ListBody>
            <DeviceServiceForm id={form} device={props.device} onSave={loadList} onClose={() => onClose()}/>
        </ListContainer>
        <Paging paging={paging} onChange={loadList}/>
    </>
}

export function DeviceServiceForm (props: any) {
    const { t } = useTranslation()

    const [data, setData] = useState<any>(null)

    const loadData = () => {
        if (props.id > 0) {
            get('ma/service_load/' + props.id).then(d => {
                if (d.ok) {
                    const datas = d.data.datas.map((d2: any) => ({ ...d2, key: Math.random() })) || []
                    setData({ ...d.data, datas: [...datas, DEFAULT_SERVICE_ITEM()] })
                }
            })
        }
        else setData(DEFAULT_SERVICE())
    }

    const saveData = (c: any) => {
        const saveData = {
            ...data,
            start: dbdate(data.start),
            end: dbdate(data.end),
            datas: filterSave(data.datas),
        }
        let device = props.device ? props.device : (data.device ? data.device.id : 0)
        post('ma/service_save/' + device, saveData).then(d => {
            if (d.ok) {
                tSuccess(t('INFO_SAVED'))
                props.onSave()
            }
        }).finally(c)
    }

    const deleteData = () => {
        dConfirm(t('CONFIRM_DELETE')).then(() => {
            get('ma/service_drop/' + props.id).then(d => {
                if (d.ok) {
                    tSuccess(t('INFO_DROPED'))
                    props.onSave()
                }
            })
        })
    }

    const onChange = (update: any) => setData((prve: any) => ({ ...prve, ...update }))

    const onItemChange = (update: any, index: any) => {
        let datas = data.datas
        datas[index] = { ...datas[index], ...update }
        if (datas[index].ignore) {
            datas[index].ignore = false
            datas.push(DEFAULT_SERVICE_ITEM())
        }
        onChange({ datas })
    }

    const onItemDrop = (key: any) => onChange({ datas: data.datas.filter((d: any) => d.key !== key) })

    return <Modal icon={iconModal(props.id)} title={t('MA_MENU_SERVICE')} md
                  open={props.id != null} onClose={props.onClose} onOpenEnd={loadData} onCloseEnd={() => setData(null)}
                  footerSave={saveData} footerDrop={props.id > 0 && deleteData}>
        {data && <>

            <div className="flex">
                <InputDate icon fixWidth label={t('MA_TEXT_DATE_IN')} value={data.start} onChange={start => onChange({ start })}/>
                <InputDate icon fixWidth label={t('MA_TEXT_DATE_OUT')} className="ml-4" value={data.end} onChange={end => onChange({ end })}/>
                <Input label={t('TEXT_MILEAGE')} className="ml-auto w-40" unit={t('UNIT_KM')} right value={data.km} onChange={km => onChange({ km })}/>
            </div>

            {!props.device && <Select url={'ac/device'} noClear label={t('TEXT_DEVICE')} value={data.device} onChange={(v, device) => onChange({ device })}/>}

            <Input label={t('MA_TEXT_GARAGE')} value={data.garage} onChange={garage => onChange({ garage })}/>
            <Input label={t('TEXT_NOTE')} multiline value={data.note} onChange={note => onChange({ note })}/>

            <ListContainer className="mb-6">
                <ListHead>
                    <div className="w-rn c grey-blue">#</div>
                    <div className="w-fill">{t('TEXT_LIST')}</div>
                    <div className="w-20 c">{t('TEXT_AMOUNT')}</div>
                    <div className="w-20 c">{t('TEXT_PRICE')}</div>
                    <div className="w-icon"/>
                </ListHead>
                <ListBody>
                    {!!data.datas && data.datas.map((d: any, index: any) => <List key={'i' + index} center>
                        <IconNew index={index} ignore={d.ignore}/>
                        <Input value={d.detail} className={'w-fill'} onChange={detail => onItemChange({ detail }, index)}/>
                        <Input value={d.count} type={'number'} className={'w-20'} center onChange={count => onItemChange({ count }, index)}/>
                        <Input value={d.price} type={'number'} className={'w-20'} right onChange={price => onItemChange({ price }, index)}/>
                        {d.ignore ? <div className="w-icon"/> : <IconDelete onClick={() => onItemDrop(d.key)} tooltip={'ลบทิ้ง'}/>}
                    </List>)}
                </ListBody>
            </ListContainer>
            <Upload m0 label={'แนบไฟล์'} multiple value={data.files} onChange={files => onChange({ files })}/>
        </>}
    </Modal>
}